import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { StaticImage } from "gatsby-plugin-image"

const Construct = () => (
  <Layout>
    <SEO title="納入実績" />
    <div>
      <section className="w-full lg:mr-0 lg:ml-auto lg:w-2/3">
        <div className="pt-32 font-mincho">
          <div className="border-l-4 border-x-primary bg-secondery py-4 pl-4 text-sm">
            <h1 className="text-3xl text-primary">キッザニア福岡</h1>
            <span className="mt-2 mb-1 block">製品 : モールディング</span>
            <span className="my1 block">設計 : アートマテリアル</span>
            <span className="my-1 block">施工 : アートマテリアル</span>
          </div>
        </div>
        <div className="mt-8 grid w-full grid-cols-1 gap-2 md:grid-cols-2 md:gap-2 ">
          <StaticImage
            src="../../images/works/kidzania_fukuoka/01.jpg"
            alt="キッザニア福岡"
            className="w-full"
            layout="constrained"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/02.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/015.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/022.jpg"
            alt="キッザニア福岡"
            className="w-full"
            layout="constrained"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/06.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/09.jpg"
            alt="キッザニア福岡"
            className="w-full"
            layout="constrained"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/010.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/018.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/023.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/03.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/011.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/013.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/014.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/07.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/05.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/08.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/012.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/04.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/016.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/017.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/021.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/020.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
          <StaticImage
            src="../../images/works/kidzania_fukuoka/019.jpg"
            alt="キッザニア福岡"
            className="w-full"
          />
        </div>
      </section>
    </div>
  </Layout>
)

export default Construct
